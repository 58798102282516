import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../context/context";

export const ProtectedRoute = ({children}: { children: JSX.Element }) => {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.access_token) return <Navigate to='/' state={{from: location}} replace/>

    return children
}