import axiosInstance from "../axios/axiosInstance";

const header = (token: string) => {
    return {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
}

const getAll = async (token: string) => {
    return axiosInstance.get('/department', header(token)).then(response => response.data);
}

const getEmployeeByDepartment = async (departmentId: number, token: string, hubId: number) => {

    return axiosInstance.get(`/employee/${hubId}/${departmentId}`, header(token)).then(response => response.data);
}

const statusUpdate = async (id: number, data: any, token: string) => {

    return axiosInstance.patch(`/employee/${id}`,  data, header(token)).then(response => response.data);
}

export {
    getAll,
    getEmployeeByDepartment,
    statusUpdate
}