import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

import { useAuth } from "../../../services/context/context";
import {
  getEmployeeByDepartment,
  statusUpdate,
} from "../../../services/department/department.Service";
import { toCapFirstChar } from "../../../services/utils/utils";

interface UserListType {
  id: number;
  firstName: string;
  lastName: string;
}

export interface ListType {
  id: number;
  user: UserListType;
  isIn: boolean;
}

export const EmpList = ({ name, id }: { name: string; id: number }) => {
  const [listEmp, setListEmp] = useState<ListType[]>([]);
  const [open, setOpen] = useState(false);

  const auth = useAuth();
  const { access_token, isLoadingStatus, hubId } = auth;

  useEffect(() => {
    isLoadingStatus.setIsLoading(true);
    getEmployeeByDepartment(id, access_token, hubId).then((res) => {
      setListEmp(res);
      isLoadingStatus.setIsLoading(false);
    });

    return () => {
      setListEmp([]);
    };
  }, [access_token, id]);

  const handleClick = async (id: number) => {
    const data = {
      isIn: true,
    };

    await statusUpdate(id, data, access_token).then((res: ListType) => {
      setListEmp((oldLIst) => {
        return oldLIst.map((item) => {
          return item.id === res.id ? { ...res, isIn: res.isIn } : item;
        });
      });
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: 2,
      }}
    >
      {isLoadingStatus.isLoading ? (
        <CircularProgress disableShrink />
      ) : (
        listEmp.map((emp) => (
          <Button
            key={emp.id}
            variant={emp.isIn ? "contained" : "outlined"}
            sx={{
              position: "relative",
            }}
            endIcon={
              emp.isIn && (
                <CheckIcon
                  sx={{
                    fontSize: "1.7rem",
                    color: "White",
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                />
              )
            }
            size={"large"}
            onClick={() => handleClick(emp.id)}
          >
            {emp.user.firstName}
            <br />
            {emp.user.lastName}
          </Button>
          // <Paper
          //   key={emp.id}
          //   sx={{
          //     p: 1,
          //     textAlign: "center",
          //     position: "relative",
          //   }}
          //   onClick={() => handleClick(emp.id)}
          // >
          //   {emp.isIn && (
          //     <CheckIcon
          //       sx={{
          //         fontSize: "1.7rem",
          //         color: "green",
          //         position: "absolute",
          //         right: 0,
          //         top: 0,
          //       }}
          //     />
          //   )}
          //   <Typography variant="h6">
          //     {toCapFirstChar(emp.user.firstName)}
          //   </Typography>
          //   <Typography variant="h6">
          //     {toCapFirstChar(emp.user.lastName)}
          //   </Typography>
          // </Paper>
        ))
      )}
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity={"success"} sx={{ width: "100%" }}>
          Thanks!
        </Alert>
      </Snackbar>
    </Box>
  );
};
