import { TabContext } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useAuth } from "../../services/context/context";
import { getAll } from "../../services/department/department.Service";
import { PanelComponent } from "./components/panel.component";
import { SideBarComponent } from "./components/sideBar.component";

export interface Department {
  name: string;
  id: number;
}

export const DashboardPage = () => {
  const auth = useAuth();
  const [value, setValue] = useState<string>("missions");
  const [departments, setDepartments] = useState<Department[]>([]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { access_token, isLoadingStatus } = auth;

  useEffect(() => {
    isLoadingStatus.setIsLoading(true);
    getAll(access_token).then((re) => {
      setDepartments(re);
      isLoadingStatus.setIsLoading(false);
    });
  }, [access_token]);

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: 1,
          gridTemplateRows: "auto",
          gridTemplateAreas: `"sidebar main main main"
            "bottom bottom bottom bottom"`,
          height: "85vh",
          p: 1,
        }}
      >
        {isLoadingStatus.isLoading && <CircularProgress disableShrink />}
        <TabContext value={value}>
          <SideBarComponent
            handleChange={handleChange}
            value={value}
            departments={departments}
          />
          <PanelComponent departments={departments} />
        </TabContext>
      </Box>
    </>
  );
};
