import React from "react";
import "./App.css";
import {Outlet, Route, Routes} from "react-router-dom";
import {BottomNavigator} from "./components/bottomNavigator/bottomNavigator.component";
import {SignInPage} from "./pages/signIn/signIn.page";
import {DashboardPage} from "./pages/dashboard/dashboard.page";
import {UserProvider} from "./services/context/context";
import {ProtectedRoute} from "./services/auth/protectedRoute";

const Layout = () => (
    <>
        <Outlet/>
        <BottomNavigator/>
    </>
);

function App() {
    return (
        <UserProvider>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path="/" element={<SignInPage/>}/>
                    <Route path="/dashboard" element={
                        <ProtectedRoute>
                            <DashboardPage/>
                        </ProtectedRoute>
                    }/>
                </Route>
            </Routes>
        </UserProvider>
    );
}

export default App;
