import React from "react";
import { Box, Tab } from "@mui/material";
import { TabList } from "@mui/lab";
import { Department } from "../dashboard.page";

interface LinkTabProps {
  label?: string;
  value?: string;
}

const LinkTab = (props: LinkTabProps) => {
  return (
    <Tab
      component={"a"}
      sx={{
        fontSize: "1.5rem",
      }}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};

export const SideBarComponent = (props: any) => {
  const { departments, handleChange, ...otherProps } = props;

  return (
    <Box
      sx={{
        gridArea: "sidebar",
        p: 2,
        borderRight: 1,
        borderColor: "divider",
        height: "80vh",
      }}
    >
      <TabList
        onChange={props.handleChange}
        arial-label={"Sidebar"}
        orientation={"vertical"}
      >
        {departments.map((dep: Department) => (
          <LinkTab label={dep.name} value={dep.name} key={dep.id} />
        ))}
      </TabList>
    </Box>
  );
};
