import { TabPanel } from "@mui/lab";
import React from "react";
import { Department } from "../dashboard.page";
import { EmpList } from "./empList.component";

export const PanelComponent = (props: any) => {
  const { departments } = props;

  return (
    <div
      role={"tabpanel"}
      style={{
        gridArea: "main",
        flexWrap: "wrap",
        marginRight: "1vw",
      }}
    >
      {departments.map((dep: Department) => (
        <TabPanel value={dep.name} key={dep.id}>
          <EmpList name={dep.name} id={dep.id} />
        </TabPanel>
      ))}
    </div>
  );
};
