import { createContext, useContext, useMemo, useState } from "react";
import axiosInstance from "../axios/axiosInstance";

export interface UserType {
  userId?: number;
  email?: string;
  password?: string;
  auth: boolean;
}

interface UserContextType {
  user: UserType;
  signIn: (user: UserType, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
  access_token: string;
  isLoadingStatus: {
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  };
  hubId: number;
}

const UserContext = createContext<UserContextType>(null!);

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<UserType>({ auth: false });
  const [access_token, setAccess_token] = useState<string>(null!);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hubId, setHubId] = useState<number>(0);

  const isLoadingStatus = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading]
  );

  const signIn = async (user: UserType, callback: VoidFunction) => {
    setIsLoading(true);
    await axiosInstance
      .post("/auth/login", {
        email: user.email,
        password: user.password,
      })
      .then((res) => {
        if (res.data.access_token) {
          setAccess_token(res.data.access_token);

          setUser({
            userId: res.data.userId,
            auth: true,
          });

          setHubId(res.data.user.hubId);
        }
        setIsLoading(false);
      });

    callback();
  };

  const signOut = (callback: VoidFunction) => {
    setAccess_token("");
    setUser({
      userId: 0,
      email: "",
      password: "",
      auth: false,
    });
    setHubId(0);
    callback();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        signIn: signIn,
        signOut,
        access_token,
        isLoadingStatus,
        hubId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useAuth = () => {
  return useContext(UserContext);
};

export { UserProvider, UserContext, useAuth };
