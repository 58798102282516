import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";

export const BottomNavigator = () => {
  const [value, setValue] = useState("login");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation
      sx={{
        width: "100%",
        gridArea: "bottom",
      }}
      showLabels
      value={value}
      onChange={handleChange}
    >
      <BottomNavigationAction
        label="login"
        value="login"
        icon={<LockIcon sx={{ fontSize: 40 }} />}
        component={Link}
        to="/"
      />
      <BottomNavigationAction
        label="dashboard"
        value="dashboard"
        icon={<HomeIcon sx={{ fontSize: 40 }} />}
        component={Link}
        to="/dashboard"
      />
    </BottomNavigation>
  );
};
