import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import Logo from "../../assets/img/messageLogo.jpg";
import { useAuth } from "../../services/context/context";

const LogoStyled = styled.img`
  with: 150px;
  height: 100px;
`;

interface IFormType {
  email: string;
  password: string;
}

export const SignInPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { signIn, isLoadingStatus } = auth;

  const { register, handleSubmit } = useForm<IFormType>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<IFormType> = async (data: any) => {
    signIn(data, () => {
      navigate("/dashboard", { replace: true });
    });
  };

  return (
    <>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "78vh",
        }}
      >
        {isLoadingStatus.isLoading && <CircularProgress disableShrink />}
        <LogoStyled src={Logo} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mt: 1, width: "400px" }}>
            <TextField
              {...register("email", { required: "Required" })}
              margin="normal"
              required
              fullWidth
              type={"email"}
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              {...register("password", { required: "Required" })}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
};
